import HeaderImage from '../blocks/HeaderImage';
import React from 'react';
import { graphql } from 'gatsby';
// import cleanCockpitData from 'Utils/cleanCockpitData';

import Metadata from '../components/Metadata';
import DefaultLayout from '../layout/Default';

const ImpressumPage = ({ data, location }) => {
  const { siteData, pageData } = data;
  const { title: siteTitle, canonicalSiteUrl } = siteData.siteMetadata;
  const { content, header, metadata, opengraph } = pageData;
  const { title, pageID, text } = content;

  return (
    <DefaultLayout>
      <HeaderImage data={header} minHeight={150} />
      <div className="defaultWidth" id={pageID}>
        <div>
          <h2>{title}</h2>
          <div className="textPage" dangerouslySetInnerHTML={{ __html: text }} />
        </div>
      </div>
      <Metadata
        data={metadata}
        opengraph={opengraph}
        defaultTitle={`${title} : ${siteTitle}`}
        canonicalSiteUrl={canonicalSiteUrl}
        path={location.pathname}
      />
    </DefaultLayout>
  );
};

export default /* cleanCockpitData( */ ImpressumPage /* ) */;

export const ImpressumPageQuery = graphql`
  query ImpressumPageQuery($language: String) {
    siteData: site {
      siteMetadata {
        title
        canonicalSiteUrl
      }
    }

    locales: allLocale(filter: { language: { eq: $language } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }

    pageData: cockpitPageImpressum(lang: { eq: $language }) {
      content {
        title
        text
      }

      header {
        id
        line1
        line2
        textPosition
        published
        linkTarget
        linkLabel
        madeInAustriaOverlay
        image {
          processed: childImageSharp {
            gatsbyImageData(layout: CONSTRAINED, width: 1600, placeholder: NONE)
          }
        }
        mobileImage {
          processed: childImageSharp {
            gatsbyImageData(layout: CONSTRAINED, width: 900, placeholder: NONE)
          }
        }
      }

      metadata {
        title
        metakeywords
        metadescription
      }

      opengraph {
        title
        description
        type
        image {
          childImageSharp {
            fixed(width: 1200, height: 630) {
              src
              width
              height
              aspectRatio
              originalName
            }
          }
        }
      }
    }
  }
`;
